import React from "react";
import "./contact.css"

function Contact() {
  return (
    <div>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta http-equiv="X-UA-Compatible" content="ie=edge"/>
      <link rel="stylesheet" href="https://unpkg.com/papercss@1.8.2/dist/paper.min.css"></link>
      <div class="paper container row flex-spaces" style={{marginBottom:"5%"}}>
        <h2>Contact Me</h2>
        <a class="paper-btn btn-large margin" style={{textAlign:"center"}} href="https://www.linkedin.com/in/dreihl/" target="_blank">LinkedIn</a>
        <a class="paper-btn btn-large margin" style={{textAlign:"center"}} href="https://twitter.com/davidreihl" target="_blank">Twitter</a>
      </div>
    </div>
  );
}

export default Contact;