import React from "react";
import "./projects.css"
import BRADMEN from "../imgs/BRADMEN.PNG"
import ROBOSUB from "../imgs/ROBOSUB.PNG"
import HYPERLOOP from "../imgs/HYPERLOOP.PNG"
import REACT from "../imgs/logo192.png"
import PYTHON from "../imgs/PYTHON.png"
import CODING from "../imgs/CODING.PNG"

function Projects() {
  return (
    <div>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta http-equiv="X-UA-Compatible" content="ie=edge"/>
      <link rel="stylesheet" href="https://unpkg.com/papercss@1.8.2/dist/paper.min.css"></link>
      <div class="paper container row flex-center" style={{marginBottom:"5%"}}>
        <h3>Here are some things I built</h3>
        <div class="card" style={{width:"14rem"}}>
          <img src={BRADMEN} alt="Team photo" style={{marginTop:"40px", marginBottom:"20px"}}/>
          <div class="card-body">
            <h4 class="card-title">Search Engine</h4>
            <h5 class="card-subtitle">Group of 7</h5>
            <p class="card-text">Worked with a group to design and develop a distributed, scalable search engine with an index of 20 million web pages to efficiently return relevant search results from around the web.</p>
          </div>
        </div>
        <div class="card" style={{width:"14rem"}}>
          <img src={ROBOSUB} alt="Robotic submarine image" style={{marginTop:"33Px", marginBottom:"20px"}}/>
          <div class="card-body">
            <a href="https://www.michiganrobosub.com" target="_blank">
              <h4 class="card-title">Michigan Robotic Submarine</h4>
              <h5 class="card-subtitle">Team of ~15</h5>
            <p class="card-text">Led a machine learning pod to create a pipeline for training models to detect objects for autonomous navigation. Finished 4th out of 54 teams in our rookie year.</p>
            </a>
          </div>
        </div>
        <div class="card" style={{width:"14rem"}}>
          <img src={HYPERLOOP} alt="Hyperloop team photo" style={{marginTop:"38Px", marginBottom:"20px"}}/>
          <div class="card-body">
            <a href="https://aero.engin.umich.edu/2018/10/30/michigan-hyperloop-demonstrates-exceptional-resourcefulness-and-determination-in-2018-spacex-hyperloop-competition/" target="_blank">
              <h4 class="card-title">Michigan Hyperloop</h4>
              <h5 class="card-subtitle">Team of ~25</h5>
              <p class="card-text">Worked with a team to develop a control system for the SpaceX Hyperloop pod competition. Studied and worked to implement a CAN bus communication system.</p>
            </a>
          </div>
        </div>
        <div class="card" style={{width:"14rem"}}>
          <img src={REACT} alt="React logo image" style={{marginTop:"25Px", marginBottom:"20px"}}/>
          <div class="card-body">
            <h4 class="card-title">This website</h4>
            <h5 class="card-subtitle">Individual<br/><br/></h5>
            <p class="card-text">Coded this website using React.js to build a simple React App. Styled with PaperCSS and other custom aspects.</p>
          </div>
        </div>
        <div class="card" style={{width:"14rem"}}>
          <img src={CODING} alt="Card example image" style={{marginTop:"60Px", marginBottom:"20px"}}/>
          <div class="card-body">
            <h4 class="card-title">Photo Sharing web app</h4>
            <h5 class="card-subtitle">Individual</h5>
            <p class="card-text">Designed and constructed an Instagram-like web application that contained all basic features. The project goal was to learn more about web development.</p>
          </div>
        </div>
        <div class="card" style={{width:"14rem"}}>
          <img src={PYTHON} alt="Card example image" style={{marginTop:"20Px", marginBottom:"20px"}}/>
          <div class="card-body">
            <h4 class="card-title">Rocket League Bot</h4>
            <h5 class="card-subtitle">Individual</h5>
            <p class="card-text">Built a simple bot for the videogame Rocket League to strengthen my Python skills. My bot would beat in-game bots of all difficulty levels.</p>
          </div>
        </div>
      </div>
  </div>
  );
}

export default Projects;