import FOOTER_PIC from './imgs/footer.png';
import './App.css';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home, Work_Experience, Projects, Contact } from '.';

function App() {
  return (
    <div>
      <Router>
        <Navbar bg="dark" variant="dark">
          <Container>
            <Navbar.Brand href="/">David Reihl</Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link href="/work-experience">Work Experience</Nav.Link>
              <Nav.Link href="/projects">Projects</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/work-experience" exact component={() => <Work_Experience />} />
          <Route path="/projects" exact component={() => <Projects />} />
          <Route path="/contact" exact component={() => <Contact />} />
        </Switch>
        <Navbar bg="dark" variant="dark" className="fixed-bottom">
          <Container>
            <p style={{color:"#FFFFFF", marginTop:"auto", marginBottom:"auto"}}>David Reihl</p>
            <p style={{color:"#FFFFFF", marginTop:"auto", marginBottom:"auto"}}>Software Developer</p>
            <p style={{color:"#FFFFFF", marginTop:"auto", marginBottom:"auto"}}>General Motors</p>
            <p style={{color:"#FFFFFF", marginTop:"auto", marginBottom:"auto"}}>Last update: January 2022</p>
          </Container>
        </Navbar>
      </Router>
    </div>
  );
}

export default App;

// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
