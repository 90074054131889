import React from "react";
import "./work_experience.css"
import GM from "../imgs/GM_Gradient_Brandmark_RGB-2021.jpg"
import ROBLOX from "../imgs/black_tilt_1200x1200.png"
import JMURRAY from "../imgs/jmurray.PNG"
import MCDS from "../imgs/McD_GoldenArches_1235_RGB.png"

function Work_Experience() {
  return (
    <div>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta http-equiv="X-UA-Compatible" content="ie=edge"/>
      <link rel="stylesheet" href="https://unpkg.com/papercss@1.8.2/dist/paper.min.css"></link>
      <div class="paper container row flex-center" style={{marginBottom:"5%"}}>
        <h2 style={{marginBottom:"5%"}}>Experience</h2>
        <fieldset class="form-group" style={{height:"450px"}}>
          <label for="paperSwitch1" class="paper-switch-tile">
            <input id="paperSwitch1" name="paperSwitch1" type="checkbox"/>
            <div class="paper-switch-tile-card border" style={{width:"500%", height:"500%"}}>
              <div class="paper-switch-tile-card-front border" style={{width:"100%", height:"100%"}}>
              <img src={GM} className="company-logos" style={{maxWidth:"100%", height:"auto"}}/>
              </div>
              <div class="paper-switch-tile-card-back border background-primary" style={{lineHeight:"30px"}}>
              <h3>General Motors<br/></h3>
              <h6>Software Developer (2021-Present)</h6>
                <p>
                  Building utilities in Django using multiprocessing and multithreading to automate storage-related tasks, resulting in drastically accelerated operations, risk reduction, and incident elimination<br/><br/>
                  Co-leading an effort to grow agile methodology adoption to increase productivity<br/><br/>
                  Working cross-functionally to address high-priority incidents and ensure data availability
                </p>
              </div>
            </div>
          </label>
          <label for="paperSwitch2" class="paper-switch-tile">
            <input id="paperSwitch2" name="paperSwitch2" type="checkbox" />
            <div class="paper-switch-tile-card border" style={{width:"500%", height:"500%", marginLeft:"445%"}}>
              <div class="paper-switch-tile-card-front border">
                <img src={ROBLOX} className="company-logos" style={{maxWidth:"100%", height:"auto", fontSize:"5px"}}/>
              </div>
              <div class="paper-switch-tile-card-back border background-primary" style={{lineHeight:"30px"}}>
                <h3>Roblox<br/></h3>
                <h6>Engineering Intern (2020)</h6>
                <p>
                  Built an internal utility page to accelerate development of a recommender system for the Avatar Shop<br/><br/>
                  Refactored existing code to make it generalizable for future development and reduce code duplication<br/><br/>
                  Fixed bugs in the Avatar Shop to improve user experience and maintain functional requirements
                </p>
              </div>
            </div>
          </label>
        </fieldset>
        <div>
          <h3>Other</h3>
          <div class="row">
            <div class="collapsible">
              <input id="collapsible1" type="checkbox" name="collapsible"/>
              <label for="collapsible1">J Murray and Co</label>
              <div class="collapsible-body">
                <span>Warehouse Worker (2018-2020)</span>
              </div>
            </div>
            <div class="collapsible">
              <input id="collapsible2" type="checkbox" name="collapsible"/>
              <label for="collapsible2">McDonald's</label>
              <div class="collapsible-body">
                <span>Crew Member (2016-2017)</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="card" style={{width:"14rem"}}>
          <img src={GM} alt="Team photo" style={{marginTop:"40px", marginBottom:"20px"}}/>
          <div class="card-body">
            <h4 class="card-title">Search Engine</h4>
            <h5 class="card-subtitle">Group of 7</h5>
            <p class="card-text">Worked with a group to design and develop a distributed, scalable search engine with an index of 20 million web pages to efficiently return relevant search results from around the web.</p>
          </div>
        </div> */}
      </div>
  </div>
  );
}

export default Work_Experience;