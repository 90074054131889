import React from "react";
import "./home.css"
import ME from "../imgs/me.JPG"

function Home() {
  return (
    <div>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta http-equiv="X-UA-Compatible" content="ie=edge"/>
      <link rel="stylesheet" href="https://unpkg.com/papercss@1.8.2/dist/paper.min.css"></link>
      <div class="paper container container-lg margin-bottom-small">
      <img src={ME} alt="Random Unsplash" class="float-left" style={{maxWidth:"50%", height:"auto", marginTop:"0%"}}/>
      <div style={{marginTop:"1%"}}>
        <h2>Hi, my name is David Reihl</h2>
        <h4>I am a software developer located in Michigan</h4>
        <h6><br/></h6>
        <h4>I was born and raised in Weidman, MI</h4>
        <h6><br/></h6>
        <h4>I graduated from the University of Michigan in 2021 with a B.S. in Computer Science</h4>
        <h6><br/></h6>
        <h4>I currently work for General Motors in Warren, MI</h4>
        <h4></h4>
        <h4></h4>
        <div style={{margin:"0 auto"}}>
          <table class="table-hover" style={{margin:"0 auto", width:"calc(0vw + 48%)", marginTop:"3.5%", textAlign:"center"}}>
            <thead>
              <tr>
                <th style={{textAlign:"center"}}>Fun Facts</th>
              </tr>
            </thead>
            <tbody style={{margin:"0 auto"}}>
              <tr>
                <td>I worked on teams building hyperloop pods and autonomous submarines</td>
              </tr>
              <tr>
                <td>I own a small piece of 2 soccer clubs</td>
              </tr>
              <tr>
                <td>I helped create a robotics program at my high school</td>
              </tr>
              <tr>
                <td>I memorized 314 digits of pi for extra credit</td>
              </tr>
              <tr>
                <td>I wrote this website using React.js</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      </div>
    </div>
  );
}

export default Home;